export default [
  // Super Administrator
  // Dashboard
  {
    path: '/superadmin/dashboard',
    name: 'superadmin-dashboard',
    component: () => import('@/views/apps/superadmin/dashboard/Dashboard.vue'),
    meta: {
      role: ['superadmin'],
      navActiveLink: 'superadmin-dashboard',
    },
  },
  {
    path: '/superadmin/pages',
    name: 'superadmin-pages.index',
    component: () => import('@/views/apps/superadmin/page/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/superadmin/pages/create',
    name: 'superadmin-pages.create',
    component: () => import('@/views/apps/superadmin/page/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'pages.index',
      navActiveLink: 'pages.index',
    },
  },
  {
    path: '/superadmin/pages/:id/builder',
    name: 'superadmin-pages.builder',
    component: () => import('@/views/apps/superadmin/page/Builder.vue'),
    meta: {
      layout: 'full',
      role: ['superadmin'],
      contentClass: 'pages.index',
      navActiveLink: 'pages.index',
    },
  },
  {
    path: '/superadmin/pages/:id',
    name: 'superadmin-pages.show',
    component: () => import('@/views/apps/superadmin/page/Show.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'pages.index',
      navActiveLink: 'pages.index',
    },
  },
  {
    path: '/superadmin/pages/:id/edit',
    name: 'superadmin-pages.edit',
    component: () => import('@/views/apps/superadmin/page/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'pages.index',
      navActiveLink: 'pages.index',
    },
  },
  {
    path: '/superadmin/promos',
    name: 'superadmin-promos.index',
    component: () => import('@/views/apps/superadmin/promo/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/superadmin/promos/create',
    name: 'superadmin-promos.create',
    component: () => import('@/views/apps/superadmin/promo/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'promos.index',
      navActiveLink: 'promos.index',
    },
  },
  {
    path: '/superadmin/promos/:id',
    name: 'superadmin-promos.show',
    component: () => import('@/views/apps/superadmin/promo/Show.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'promos.index',
      navActiveLink: 'promos.index',
    },
  },
  {
    path: '/superadmin/promos/:id/edit',
    name: 'superadmin-promos.edit',
    component: () => import('@/views/apps/superadmin/promo/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'promos.index',
      navActiveLink: 'promos.index',
    },
  },
  {
    path: '/superadmin/transactions',
    name: 'superadmin-transactions.index',
    component: () => import('@/views/apps/superadmin/transaction/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/superadmin/transactions/create',
    name: 'superadmin-transactions.create',
    component: () => import('@/views/apps/superadmin/transaction/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'transactions.index',
      navActiveLink: 'transactions.index',
    },
  },
  {
    path: '/superadmin/transactions/:id',
    name: 'superadmin-transactions.show',
    component: () => import('@/views/apps/superadmin/transaction/Show.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'transactions.index',
      navActiveLink: 'transactions.index',
    },
  },
  {
    path: '/superadmin/transactions/:id/edit',
    name: 'superadmin-transactions.edit',
    component: () => import('@/views/apps/superadmin/transaction/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'transactions.index',
      navActiveLink: 'transactions.index',
    },
  },
  {
    path: '/staffs',
    name: 'staffs.index',
    component: () => import('@/views/apps/superadmin/staff/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/staffs/create',
    name: 'staffs.create',
    component: () => import('@/views/apps/superadmin/staff/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'staffs.index',
      navActiveLink: 'staffs.index',
    },
  },
  {
    path: '/staffs/:id/edit',
    name: 'staffs.edit',
    component: () => import('@/views/apps/superadmin/staff/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'staffs.index',
      navActiveLink: 'staffs.index',
    },
  },
  {
    path: '/superadmin/letter-of-assignments',
    name: 'superadmin-letter-of-assignments.index',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/superadmin/letter-of-assignments/create',
    name: 'superadmin-letter-of-assignments.create',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/tu/letter-of-assignments/:id/show',
    name: 'superadmin-letter-of-assignments.show',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Show.vue'),
    meta: {
      role: ['superadmin', 'tu'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/superadmin/letter-of-assignments/:id/edit',
    name: 'superadmin-letter-of-assignments.edit',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/superadmin/trainings',
    name: 'superadmin-trainings.index',
    component: () => import('@/views/apps/trainer/training/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/create',
    name: 'superadmin-trainings.create',
    component: () => import('@/views/apps/trainer/training/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tambah Pelatihan',
      breadcrumb: [
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId',
    name: 'superadmin-trainings.show',
    component: () => import('@/views/apps/trainer/training/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  // chapters
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId',
    name: 'superadmin-chapters.show',
    component: () => import('@/views/apps/trainer/training/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/create',
    name: 'superadmin-chapters.create',
    component: () => import('@/views/apps/trainer/training/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Classroom Task
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks',
    name: 'superadmin-chapter-tasks.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/DetailTask.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
    name: 'superadmin-task-multiplechoice-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/MultipleChoice.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/task-results/:taskResultId/correction',
    name: 'superadmin-task-results.correction',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-correction/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Koreksi Tugas',
      back: 'trainings.show',
      tab: '2',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Tugas',
          to: 'trainings.show',
        },
        {
          text: 'Koreksi Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
    name: 'superadmin-task-essay-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks/create',
    name: 'superadmin-chapter-tasks.create',
    component: () => import('@/views/apps/trainer/training/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tambah Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tambah Tugas',
          active: true,
        },
      ],
    },
  },

  {
    path: '/superadmin/training-requests',
    name: 'superadmin-training-requests.index',
    component: () => import('@/views/apps/staff/training-request/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-training-requests.index',
    },
  },
]
