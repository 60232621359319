import axios from '@/libs/axios'

export function reqLogin(payload) {
  return axios.post('api/auth/login', payload)
}
export function reqRegister(payload) {
  return axios.post('api/auth/register', payload)
}
export function reqGetMe(payload) {
  return axios.get('api/auth/me', { params: payload })
}
export function reqUpdateProfile(payload) {
  return axios.post('api/auth/change-profile', payload)
}
export function reqUpdatePassword(payload) {
  return axios.post('api/auth/change-password', payload)
}
