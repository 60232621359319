import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, checkAuthorizeRole,
} from '@/auth/utils'
// import apps from './routes/apps'
import pages from './routes/pages'
// import others from './routes/others'

// Role Router
import user from './routes/user'
import trainer from './routes/trainer'
import staff from './routes/staff'
import tu from './routes/tu'
import superadmin from './routes/superadmin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: 'simpelbptik',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: checkAuthorizeRole('dashboard') } },
    // ...apps,
    ...user,
    ...trainer,
    ...staff,
    ...tu,
    ...superadmin,
    ...pages,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // If auth required, check login. If login fails redirect to login page
  if (!isUserLoggedIn()) {
    if (to.name === 'auth-login' || to.name === 'auth-register' || !to.meta.role) {
      next()
    } else {
      next({ name: 'auth-login' })
    }
  } else if (!canNavigate(to) && to.meta.role) {
    return next({ name: 'misc-not-authorized' })
  }

  // If logged in, redirect to home page according to role
  if (to.name === 'auth-login' && isLoggedIn) {
    return next({ name: checkAuthorizeRole('dashboard') })
  }

  // If logged in, redirect to home page according to role

  // If route is not found

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(userData.roles[0].name === 'student' ? '/' : { name: 'access-control' })
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
