export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/apps/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/apps/auth/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/profile',
    name: 'user.profile',
    component: () => import('@/views/apps/user/profile/Index.vue'),
    meta: {
      contentClass: 'user.profile',
      navActiveLink: 'user.profile',
    }
  },

  //pages
  {
    path: '/',
    name: 'pages.landing-page',
    component: () => import('@/views/tekov/landingpage/LandingPage.vue'),
    meta: {
      layout: 'horizontal',
    }
  },
  {
    path: '/pages/trainings/:trainingId',
    name: 'pages.trainings.show',
    component: () => import('@/views/tekov/pages/program/Program.vue'),
    meta: {
      layout: 'horizontal',
    }
  },
  {
    path: '/pages/portfolio',
    name: 'pages.portfolio',
    component: () => import('@/views/tekov/pages/program/Program.vue'),
    meta: {
      layout: 'horizontal',
    }
  },
  {
    path: '/pages/promo',
    name: 'pages.promo',
    component: () => import('@/views/tekov/pages/promo/Promo.vue'),
    meta: {
      layout: 'horizontal',
    }
  },
  {
    path: '/pages/articles',
    name: 'pages.articles.index',
    component: () => import('@/views/tekov/pages/article/Article.vue'),
    meta: {
      layout: 'horizontal',
    }
  },
  {
    path: '/pages/articles/:id',
    name: 'pages.article-detail',
    component: () => import('@/views/tekov/pages/article/ArticleDetail.vue'),
    meta: {
      layout: 'horizontal',
    }
  },
  {
    path: '/pages/about',
    name: 'pages.about',
    component: () => import('@/views/tekov/pages/about/About.vue'),
    meta: {
      layout: 'horizontal',
    }
  },

  

  // Miscellaneous
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/:id',
    name: 'page',
    component: () => import('@/views/apps/page/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]
